// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/paul/Development/dinonauts/dinonauts-website/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-characters-js": () => import("/Users/paul/Development/dinonauts/dinonauts-website/src/pages/characters.js" /* webpackChunkName: "component---src-pages-characters-js" */),
  "component---src-pages-index-js": () => import("/Users/paul/Development/dinonauts/dinonauts-website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("/Users/paul/Development/dinonauts/dinonauts-website/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-support-js": () => import("/Users/paul/Development/dinonauts/dinonauts-website/src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-videos-js": () => import("/Users/paul/Development/dinonauts/dinonauts-website/src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */)
}

exports.data = () => import("/Users/paul/Development/dinonauts/dinonauts-website/.cache/data.json")

